import React from 'react';

const ServiceAvailability = () => {
    return (
        <>
            <div style={{ 
                width: '100%', 
                height: 'auto', 
                backgroundColor: '#000312',
                backgroundImage: 'url("back.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat:'no-repeat',
            
            }}>
                <h1 style={{textAlign:'center',color:'white',fontWeight:'bold'}}>Service Availability</h1>
                <div className='container' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', padding: '20px 0' }}>
                    <div className='child1' style={{ margin: '20px' }}>
                        <p style={{ color: '#75DAE6', fontFamily: 'Poppins', fontSize: '52px', fontWeight: '700', lineHeight: '64px' }}>Americas</p>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='co1.png' style={{ width: '35px', height: '28px' }} />
                            <p style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }} className='mt-3'>São Paulo, Brazil
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='co3.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Texas, USA
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='co3.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Iowa, USA
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='co4.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Virginia, USA
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='https://flagcdn.com/h20/ca.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Toronto, Canada
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='https://flagcdn.com/h20/mx.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Querétaro, Mexico

                            </p>
                        </div>
                        <p style={{ color: '#75DAE6', fontFamily: 'Poppins', fontSize: '52px', fontWeight: '700', lineHeight: '64px' }}>Europe</p>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='https://flagcdn.com/h20/ie.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Dublin, Ireland
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='https://flagcdn.com/h20/es.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Madrid, Spain

                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='https://flagcdn.com/h20/nl.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Amsterdam, Netherlands
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='https://flagcdn.com/h20/fr.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Paris, France
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='https://flagcdn.com/h20/de.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Frankfurt, Germany
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='https://flagcdn.com/h20/it.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Milan, Italy
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='e6.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>London, UK
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='e7.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Gävle, Sweden
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='e8.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Zurich, Switzerland
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='e9.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Oslo, Norway
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='e10.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Warsaw, Poland
                            </p>
                        </div>
                    </div>
                    <div className='child2' style={{ margin: '20px' }}>
                        <p style={{ color: '#75DAE6', fontFamily: 'Poppins', fontSize: '52px', fontWeight: '700', lineHeight: '64px' }}>Africa</p>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='https://flagcdn.com/h20/za.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Johannesburg, South Africa
                            </p>
                        </div>
                        <p style={{ color: '#75DAE6', fontFamily: 'Poppins', fontSize: '52px', fontWeight: '700', lineHeight: '64px' }}>Middle East</p>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='m1.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Dubai, UAE
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='m2.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Doha, Qatar
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='m3.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Riyadh, Saudi Arabia (soon)
                            </p>
                        </div>
                    </div>
                    <div className='child3' style={{ margin: '20px' }}>
                        <p style={{ color: '#75DAE6', fontFamily: 'Poppins', fontSize: '52px', fontWeight: '700', lineHeight: '64px' }}>Asia Pacific</p>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='https://flagcdn.com/h20/hk.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Hong Kong, Hong Kong SAR
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='https://flagcdn.com/h20/sg.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Singapore, Singapore
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='https://flagcdn.com/h20/jp.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Tokyo, Japan
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='a4.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Seoul, South Korea
                            </p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='a5.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Chennai, India
                            </p>
                        </div>
                        
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <img src='aus.png' style={{ width: '35px', height: '28px' }} />
                            <p className='mt-3' style={{ marginLeft: '10px', fontSize: '24px', fontWeight: '500', color: 'white', fontFamily: 'Poppins' }}>Sydney, Australia
                            </p>
                        </div>
                    </div>
                </div>
                <style>
                    {`
                        @media (max-width: 768px) {
                            .container {
                                flex-direction: column;
                                align-items: center;
                                text-align:center;
                            
                            }
                            .child1, .child2, .child3 {
                                
                                width: 100%;
                                text-align: center;
                            }
                            .child1 p, .child2 p, .child3 p {
                                font-size: 36px;
                                
                            }
                            .child1 div, .child2 div, .child3 div {
                                justify-content: center;
                                text-align:center;
                            }
                            .child1 p, .child2 p, .child3 p, .child1 img, .child2 img, .child3 img {
                                margin-bottom: 10px;
                                margin-left:-40px;
                            }
                        }
                    `}
                </style>
              
            </div>
           
        </>
    );
}

export default ServiceAvailability;
