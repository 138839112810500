import React from "react";

const VideoPlayer = () => {
  return (
    <div>
      
      <video
        width="640"
        height="360"
        controls
        style={{ border: "1px solid #ccc", borderRadius: "8px" }}
      >
        <source src="/dns.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
